@import "../theme.scss";

#formLandingPage {
	background-color: $color-light-grey;
	padding: 50px $standard-padding-x;

	.mainContent {
		> .inner {
			max-width: 1350px;
			min-height: 500px;
			background-color: $color-white;
			box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
			padding: 40px $standard-padding-x;
			align-content: space-between;

			.content {
				max-width: 750px;
				text-align: center;

				.title {
					color: $color-blue;
					font-size: clamp(22px, 3vw, 24px);
					font-weight: 600;
					width: auto;
					display: inline-block;

					&::after {
						width: 20%;
						display: block;
						content: "";
						background-color: $color-red-light;
						height: 2px;
						margin-top: 10px;
						border-bottom: unset;
						padding-top: 0;
					}
				}

				.intro {
					margin-top: 20px;
					font-size: 14px;

					span {
						font-size: 12px;
					}
				}

				.buttonContainer {
					margin-top: 80px;

					> p {
						font-size: 16px;
						font-weight: 500;
					}

					.buttons {
						grid-template-columns: repeat(3, auto);
						grid-gap: 20px;
						margin-top: 30px;

						button {
							width: 230px;

							a,
							p {
								padding-top: 0.85em;
								padding-bottom: 0.85em;
							}

							&:first-child {
								a,
								p {
									padding: 0.1em 1em;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: $screenBreak-mobile) {
	#formLandingPage {
		padding: 0;

		.mainContent {
			> .inner {
				.content {
					.buttonContainer {
						margin-top: 20px;

						.buttons {
							grid-template-columns: repeat(1, 1fr);

							button {
								width: 100%;
								max-width: 230px;
							}
						}
					}
				}
			}
		}
	}
}
